import React from "react"
import { graphql } from "gatsby"
import Image from "gatsby-image"
import { AnchorLink } from "gatsby-plugin-anchor-links"
import {
  Button,
  Box,
  Heading,
  Container,
  Text,
  VStack,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
  Link,
} from "@chakra-ui/core"
import { HiPhone } from "react-icons/hi"
import { FaFacebook } from "react-icons/fa"

import Layout from "components/layout"
import SEO from "components/seo"

const BlogIndex = ({ data }) => {
  const contactData = data.site.siteMetadata.contact
  return (
    <Layout>
      <SEO title="Prohood | รับทำความสะอาด" />
      <Box
        p="2"
        bg="blue.400"
        color="white"
        fontSize={["sm"]}
        d="flex"
        justifyContent="center"
        textAlign="center"
      >
        เครื่องดูดควันมีปัญหา โทร {contactData.phone} (24 ชม.)
      </Box>

      <VStack spacing="6" p="8">
        <VStack spacing="0">
          <Heading as="h1" textStyle="h1" textAlign="center" color="blue.500">
            ล้าง ซ่อม แก้ทุกปัญหา
          </Heading>
          <Heading as="h1" textStyle="h1" textAlign="center">
            เครื่องดูดควัน
          </Heading>
        </VStack>
        <Text textAlign="center" textStyle="body-1">
          ล้างปล่องดูดควัน ซ่อมระบบดูดควัน สำหรับร้านอาหาร บ้าน โรงแรม
        </Text>

        <AnchorLink to="/#contact">
          <Button colorScheme="blue" size="lg">
            ติดต่อเราทันที
          </Button>
        </AnchorLink>
      </VStack>
      {data.allHeroImg.edges.map(edge => {
        return (
          <Image
            fluid={edge.node.fluid}
            alt="hood mai dude"
            tw="rounded object-contain "
          />
        )
      })}

      <Box
        d="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
        p="8"
      >
        <Heading as="h2" textStyle="h2" textAlign="center" mb="4">
          ฮูดไม่ดูด ลมไม่แรง เสียงดังผิดปกติ
        </Heading>
        <Heading as="p" textStyle="h4" color="gray.700">
          ให้เราช่วยคุณ
        </Heading>
        <Box w={["80%"]} my="8">
          <Image fluid={data.logo.childImageSharp.fluid} alt="prohood logo" />
        </Box>
        <VStack spacing="4">
          <Box d="flex">
            <Heading as="p" textStyle="h3">
              จบทุกเรื่อง
            </Heading>
            <Heading as="p" textStyle="h3" color="blue.500" ml="1">
              เครื่องดูดควัน
            </Heading>
          </Box>
          <Box d="flex">
            <Heading as="p" textStyle="h3">
              บริการโดยทีมช่าง
            </Heading>
            <Heading as="p" textStyle="h3" color="blue.500">
              มืออาชีพ
            </Heading>
          </Box>
          <Box d="flex">
            <Heading as="p" textStyle="h3">
              ประสบการณ์กว่า
            </Heading>
            <Heading as="p" textStyle="h3" color="blue.500" ml="2">
              20 ปี
            </Heading>
          </Box>
        </VStack>
      </Box>

      <Container centerContent>
        <Heading
          as="h3"
          textStyle="h3"
          borderBottom="2px"
          borderBottomColor="red.600"
        >
          บริการของเรา
        </Heading>
        <Box w="full" my="4">
          <Accordion defaultIndex={[0]} allowMultiple>
            <AccordionItem>
              <AccordionButton>
                <Box flex="1" textAlign="left">
                  <Text textStyle="h4">ล้างระบบดูดควัน</Text>
                </Box>
                <AccordionIcon />
              </AccordionButton>
              <AccordionPanel>
                ล้างปล่องดูดควัน ล้าง blower
                กำจัดคราบน้ำมันที่อาจก่อให้เกิดเพลิงไหม้ ล้างฮูดร้านอาหาร
                ล้างฮูดโรงแรม
              </AccordionPanel>
            </AccordionItem>

            <AccordionItem>
              <AccordionButton>
                <Box flex="1" textAlign="left">
                  <Text textStyle="h4">ล้าง</Text>
                </Box>
                <AccordionIcon />
              </AccordionButton>
              <AccordionPanel>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
                enim ad minim veniam, quis nostrud exercitation ullamco laboris
                nisi ut aliquip ex ea commodo consequat.
              </AccordionPanel>
            </AccordionItem>
          </Accordion>
        </Box>
      </Container>
      <Container
        my="8"
        d="flex"
        flexDirection="column"
        alignItems="start"
        justifyContent="start"
        id="contact"
      >
        <Heading
          as="h3"
          textStyle="h3"
          borderBottom="2px"
          borderBottomColor="red.600"
          mb="4"
        >
          ติดต่อเรา
        </Heading>
        <Box fontSize={["lg"]}>
          <Box d="flex" alignItems="center">
            <Text color="blue.500" mr="2">
              <HiPhone />
            </Text>
            <Text>
              {contactData.phone} ({contactData.name})
            </Text>
          </Box>
          <Box d="flex" alignItems="center">
            <Text color="blue.500" mr="2">
              <FaFacebook />
            </Text>
            <Link
              color="blue.500"
              textDecor="underline"
              isExternal
              href={`https://${contactData.facebook}`}
            >
              {contactData.facebook}
            </Link>
          </Box>
        </Box>
      </Container>
    </Layout>
  )
}

export default BlogIndex

export const pageQuery = graphql`
  query HomePageQuery {
    allHeroImg: allImageSharp(
      filter: { fluid: { src: { regex: "/placeholder/" } } }
    ) {
      edges {
        node {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
    logo: file(absolutePath: { regex: "/prohood-logo.png/" }) {
      childImageSharp {
        fluid(fit: CONTAIN) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    site {
      siteMetadata {
        title
        contact {
          name
          phone
          facebook
        }
      }
    }
  }
`
